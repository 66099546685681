<script>
import RateInfoCircles from "@/components/rate-card/RateInfoCircles.vue";
import AppTag from "@/components/ui/AppTag.vue";
import { bus } from "@/router/bus";

export default {
  name: "RateCardHeader",

  components: {
    RateInfoCircles,
    AppTag
  },

  props: {
    rate: {
      type: Object,
      required: true
    },

    ratePage: {
      type: Boolean,
      required: false,
      default: false
    },

    type: {
      type: String,
    }
  },

  data: () => ({
    showLineInfo: false,
    isBlockZero: false,
    displayedClientCount: 2,
    windowWidth: window.innerWidth,
  }),

  computed: {
    isAuth() {
      return this.$_getsetting('is_auth');
    },

    getClientId() {
      return Number(this.$_getsetting('client_id'))
    },

    clientList() {
      return this.rate.client_list.slice(0, this.displayedClientCount)
    },
  },

  mounted() {
    if (this.getClientId === -1) {
      this.displayedClientCount = 3
    }
  },

  methods: {
    checkBlockVisibility(block) {
      this.isBlockZero = block.offsetWidth === 0 && block.offsetHeight === 0;
    },

    getStatusTitle(item) {
      switch (item.lack_of_empty_equipment_type) {
        case 0:
          return 'Ограничений по наличию оборудования нет';
        case 1:
          return 'Есть ограничения по наличию оборудования';
        case 2:
          return 'Наличие оборудования требует уточнения';
      }
    },

    openLineInfo() {
      bus.$emit('showLineInfo', this.rate.line_id)
    },

    getStatusClass(item) {
      switch (item.lack_of_empty_equipment_type) {
        case 0:
          return 'no-limits';
        case 1:
          return 'not-equipment';
        case 2:
          return 'need-info';
      }
    },

    getTime() {
      if (this.rate.all_time < 9999 && this.rate.tranzit_time < 9999) {
        return this.rate.tranzit_time + '/' + this.rate.all_time;
      } else {
        return 0;
      }
    },

    isTimeVerified() {
      return Number(this.rate.is_unverified) === 0;
    },

    getVerificationText() {
      if (this.isTimeVerified() && this.getTime() !== 0) {
        return 'Проверено Vedexx';
      } else if (this.getTime() === 0) {
        return 'Нет статистики по транзитному сроку';
      }
    },

    getTerminalName() {
      return (Number(this.rate.is_direct) === 0) ?
        this.rate.terminal_name :
        (Number(this.rate.is_ete) === 1) ?
          this.rate.across_place_name_ext : ""
    },

    hasTransitTime() {
      return this.rate.detail[0].tranzit_time
    },

    getTotal(value) {
      return parseFloat(value).toLocaleString('ru-RU')
    },
  },
}
</script>

<template>
  <div class="card-header">
    <div class="card-header__top">
      <div class="card-header__top-plate" :class="'card-header__top-plate-' + getStatusClass(rate)">
        {{ getStatusTitle(rate) }}
      </div>
      <div class="card-header__top-info">
        <span>{{ rate.unit_code }}</span>
        <span>{{ rate.delivery_term_code }}</span>
      </div>
      <div class="card-header__top-time">
        <div
          v-if="([isTimeVerified() && getTime() !== 0] || getTime() === 0)"
          :class="isTimeVerified() && getTime() !== 0
            ? 'card-header__top-time-check-green'
            : hasTransitTime() !== -1 && !isTimeVerified() ? '' : 'card-header__top-time-check-orange'"
          class="card-header__top-time-check"
        >
          {{ getVerificationText() }}
        </div>
        <div
          v-if="getTime() !== 0"
          :class="isTimeVerified() ? 'card-header__top-plate-days-checked' : 'card-header__top-plate-days'"
          class="card-header__top-plate"
        >
          <span>в пути</span> {{ getTime() }} дней
        </div>
      </div>
    </div>
    <div class="card-header__top-client-list" v-if="getClientId === -1">
      <AppTag
        v-for="item in clientList"
        :key="item.client_id"
        :label="item.client_name"
        color="blue"

      />

      <div @click="displayedClientCount = rate.client_list.length"
           v-if="displayedClientCount < rate.client_list.length">
        <AppTag
          :label="'+' +( rate.client_list.length - 2 )"
          color="blue"
        />
      </div>
    </div>
    <div class="card-header__title" @click="$emit('openRatePage')">{{
        rate.title.replace('(' + rate.unit_code + ')', '')
      }}
    </div>

    <div class="card-header__bottom" v-if="isAuth">
      <div class="card-header__bottom-items">
        <RateInfoCircles
          v-if="ratePage"
          @block-mounted="checkBlockVisibility"
          :class="isBlockZero ? '' : 'card-header__bottom-circle'"
          :rate="rate"
        />
        <div class="card-header__bottom-line" v-else>
          Перевозчик:&nbsp;
          <span
            v-if="Number(rate.is_hide_line) === 0 || getClientId === -1"
            @click="openLineInfo"
          >
          <span
            :class="Number(rate.has_line_info) === 1 ? 'card-header__bottom-line-link' : 'card-header__bottom-line-name'">
            {{ rate.line_name }}
          </span>
        </span>
          <span v-else class="card-header__bottom-line-name">Vedexx</span>
        </div>
        <div v-if="rate.is_agent && getClientId === -1" class="card-header__agent"
             :class="ratePage ? 'card-header__agent--inside' : ''">
          Агент:
          <span class="card-header__agent-name">{{ rate.charterer_name }}</span>
        </div>
        <div class="card-header__bottom-line">
          Терминал:&nbsp;
          <span class="card-header__bottom-line-name">{{ getTerminalName() }}</span>
        </div>
      </div>
      <hr class="card-header__bottom-hr"/>

      <div class="card-header__bottom-total" v-if="ratePage">
        Итого:
        <span class="card-header__bottom-total-price">{{ getTotal(rate.total) }}&nbsp;&#8381;</span>
      </div>
    </div>

    <div class="card-header__tags" v-if="type === 'recommend'">
      <div class="card-header__tag-row" v-for="(tag, index) in rate.pls_mns" :key="index">
        <div class="card-header__tag" v-if="tag.value == 1">
          {{ tag.desc_rus }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.card-header {
  &__top {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: $mobile-width) {
      flex-direction: column;
    }

    @media print {
      flex-direction: row;
    }

    &-plate {
      text-align: center;
      border-radius: 8px;
      padding: 6px 12px;

      @include font-description-semibold-0;

      @media (max-width: 1368px) {
        order: 0;
        margin-right: 0;
      }

      @media (max-width: $tablet-width) {
        > span {
          display: none;
        }
      }

      @media (max-width: $mobile-width) {
        > span {
          display: inline-block;
        }
      }

      &-no-limits {
        background: var(--green-10);
        color: var(--green-100);
      }

      &-not-equipment {
        background: var(--red-10);
        color: var(--red-100);
      }

      &-need-info {
        background: var(--orange-20);
        color: var(--orange-70);
      }

      &-days {
        background: var(--blue-50);
        color: #FFFFFF;

        &:nth-child(3) {
          margin-left: auto;
        }

        @media (max-width: $tablet-width) {
          &-checked {
            padding: 6px 16px;
          }
        }

      }

      &-days-checked {
        background: var(--green-60);
        color: #FFFFFF;

        &:nth-child(3) {
          margin-left: auto;
        }
      }
    }

    &-client-list {
      margin-top: 8px;
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
    }

    &-info {
      @include font-description-semibold-0;

      line-height: 19.12px;
      letter-spacing: 0.01em;
      color: var(--grey-70);
      align-content: center;

      @media (max-width: 1368px) {
        order: 1;
        flex: 1 1 100%;
        margin-top: 20px;
      }

      @media (max-width: $mobile-width) {
        margin-top: 16px;
      }

      > span {
        margin-right: 24px;

        &:first-child {
          margin-left: 24px;

          @media (max-width: $tablet-width) {
            margin-left: 0;
          }
        }
      }
    }

    &-time {
      display: flex;
      margin-left: auto;
      gap: 16px;

      @media (max-width: $tablet-width) {
        gap: 11px;
      }

      @media (max-width: $mobile-width) {
        margin-top: 16px;
        margin-left: 0;
      }

      &-check {
        align-items: center;
        margin-left: auto;
        display: flex;
        justify-content: flex-end;

        @include font-description-semibold-0;

        @media (max-width: $tablet-width) {
          order: 0;
          flex: 1 1 auto;
          margin-right: 0;
        }

        @media (max-width: $mobile-width) {
          margin-left: 0;
          justify-content: flex-start;
        }

        &-green {
          color: var(--green-100);

          &:before {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url("../../assets/img/icons/check-green.svg") no-repeat;
            background-size: contain;
            //background-repeat: no-repeat;
            margin-right: 4px;
          }
        }

        &-orange {
          color: var(--orange-70);

          &:before {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url("../../assets/img/icons/warning-circle-orange.svg") no-repeat;
            background-size: contain;
            margin-right: 4px;
          }
        }
      }
    }
  }

  &__title {
    @include font-headline-2;

    color: var(--grey-100);
    font-size: 24px;
    line-height: 26px;
    font-weight: 600;
    letter-spacing: 0.24px;
    margin: 20px 0 16px;
    transition: .2s;
    cursor: pointer;

    &:hover {
      color: var(--grey-70);
    }

    @media (max-width: $tablet-width) {
      font-size: 20px;
      line-height: 26px;
      margin: 20px 0 8px;
    }

    @media (max-width: $mobile-width) {
      margin: 16px 0;
    }

    @media print {
      margin: 0;
      font-size: 16px;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px 56px;

    &:not(:has(.card-header__bottom-circle)) {
      gap: 16px 0;
    }

    @media (max-width: $mobile-width) {
      flex-direction: column;
      align-items: self-start;
    }

    @media print {
      flex-direction: row;
    }

    &-circle {
      margin-right: -56px;

      @media print {
        display: none;
      }

      @media (max-width: $tablet-width) {
        margin-right: 0;
        order: 3;
      }

      @media (max-width: $mobile-width) {
        order: 2;
        margin-top: 16px
      }
    }

    &-line {
      color: var(--grey-70);
      letter-spacing: 0.14px;
      display: flex;
      align-items: center;

      @include font-subtitle-regular;

      @media (max-width: $tablet-width) {
        order: 1
      }

      @media (max-width: $mobile-width) {
        margin-top: 0;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      &-link {
        color: var(--blue-50);
        letter-spacing: 0.14px;
        text-decoration: underline;
        cursor: pointer;

        @include font-subtitle-medium;

        &:active {
          text-decoration: none;
        }
      }

      &:first-child {
        min-width: fit-content;
      }

      &-name {
        color: var(--grey-100);
        font-weight: 600;
      }
    }

    &-hr {
      display: none;
      border: 0.5px solid var(--grey-20);
      width: 100%;
      margin: 24px 0;

      @media print {
        display: none !important;
        margin: 0;
      }

      @media (max-width: $tablet-width) {
        display: block;
        flex: 1 1 100%;
        order: 2;
      }

      @media (max-width: $mobile-width) {
        margin: 16px 0;
        display: block;
        flex: 1;
        order: 3;
      }
    }

    &-total {
      color: var(--grey-70);
      text-align: right;
      letter-spacing: 0.14px;
      margin-left: auto;

      @include font-subtitle-medium;


      @media (max-width: $tablet-width) {
        order: 4;
      }

      &-price {
        @include font-headline-2;

        font-size: 32px;
        letter-spacing: 0.32px;
        margin-left: 6px;

        @media (max-width: $mobile-width) {
          color: var(--blue-70);
        }

        @media print {
          font-size: 16px;
        }
      }
    }

    &:has(.card-header__agent--inside) {
      .card-header__agent--inside {
        order: 3;

        @media (max-width: $tablet-width) {
          order: unset;
        }
      }
    }

    &-items {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px 56px;

      @media (max-width: $mobile-width) {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px 0;
      }

    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 24px;

    @media (max-width: $mobile-width) {
      margin-top: 16px;
    }

  }

  &__tag {
    @include font-body-1;

    padding: 2px 12px;
    border-radius: 8px;

    color: var(--blue-70);
    background-color: var(--blue-20);
    font-size: 14px;


  }

  &__agent {
    font-size: 14px;

    &-name {
      font-weight: 600;
    }
  }


}
</style>
